
    .singleContainer {
      flex: 6;
  
      .top {
        padding: 20px;
        display: flex;
        gap: 20px;
  
        .left {
          flex: 1;
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          padding: 20px;
          position: relative;
  
          .editButton {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px;
            font-size: 12px;
            color: #7451f8;
            background-color: #7551f818;
            cursor: pointer;
            border-radius: 0px 0px 0px 5px;
          }
  
          .item {
            display: flex;
            gap: 20px;
  
            .itemImg {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              object-fit: cover;
            }
  
            .details {
              .itemTitle {
                margin-bottom: 10px;
                color: #555;
              }
  
              .detailItem {
                margin-bottom: 10px;
                font-size: 14px;
  
                .itemKey {
                  font-weight: bold;
                  color: gray;
                  margin-right: 5px;
                }
  
                .itemValue {
                  font-weight: 300;
                }
              }
              .resetButton {
                padding: 5px 5px;
                border-radius: 5px;
                color: darkblue;
                border: 2px solid rgba(0, 0, 139, 0.596);
                cursor: pointer;
              }
              .saveButton {
                padding: 5px 5px;
                border-radius: 5px;
                color: rgb(0, 139, 42);
                border: 2px solid rgb(0, 139, 42);
                cursor: pointer;
              }
         
            }
          }
        }
        .completeButton {
          padding: 2px 5px;
          border-radius: 5px;
          color: rgb(0, 139, 49);
          border: 1px dotted rgba(0, 0, 139, 0.596);
          cursor: pointer;
        }
        .completeButton:hover {
          padding: 2px 5px;
          border-radius: 5px;
          background-color: rgb(0, 139, 49);
          color: white;
          border: 1px rgb(0, 139, 49);
          cursor: pointer;
        }
        .right {
          flex: 2;
        }
      }
  
      .bottom {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 10px 20px;
      }
  
      .title {
        font-size: 16px;
        color: lightgray;
        margin-bottom: 20px;
      }
    }
  
  