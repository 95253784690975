.sidebar {
  flex: 2;
  border-right: 0.5px solid rgb(230, 227, 227);
  background-color: white;


  .top {
    height: 50px;
    padding: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid rgb(230, 227, 227);
    @media (max-width: 1200px) {
      display: none;
      padding: 20px;
      justify-content: space-between;
    }
    .logo{
      color: #333;
      font-weight: bold;
    }
    .menuIcon.show{
      display: none;
      
      // @media screen and (max-width: 1200px) {
      //   display: block;
      //   cursor: pointer;
      // border: none;
      // background-color: #9a9999;;
      // color: white;
      // z-index: 2;
      // }
    }
    .menuIcon.hide{
      display: none;
    
      // @media screen and (max-width: 1200px) {
      //   display: block;
      //   cursor: pointer;
      //   border: none;
      //   background-color: #ffffff;;
      //   color: black;
      // }
    }
  }


  .center {
    
    display: flex;
    padding-left: 10px;
    @media screen and (max-width: 1200px) {
      background: var(--white);
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      max-width: 320px;
      height: 100vh;
      padding: 20px;
      -webkit-box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
              box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
      overflow-y: scroll;
      -ms-scroll-chaining: none;
          overscroll-behavior: contain;
      display: hidden;
      -webkit-transition: 0.5s ease;
      -o-transition: 0.5s ease;
      transition: 0.5s ease;
      z-index: 20;



  }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
       
     
      .title {
        font-size: 10px;
        font-weight: bold;
        color: #999;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #ece8ff;
        }

        .icon {
          font-size: 18px;
          color: #3E1B11;
        }

        span{
            font-size: 13px;
            font-weight: 600;
            color: #888;
            margin-left: 10px;
        }
      }
    }
  }

  .mobileCenter.hide{
    display: none;   
  }

  .mobileCenter.show{
    display: flex;
    position: absolute;
    z-index: 1;
    background-color: #9a9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: left;
    align-items: center;
  
    -webkit-box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);
            box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.1);

    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    ul {
      height: 100vh;
      overflow-y: scroll;
      -ms-scroll-chaining: none;
          overscroll-behavior: contain;
      min-height:auto;
      overflow: scroll;
      background-color: white;
      list-style: none;
      margin: 0;
      padding: 0;

     
      .title {
        font-size: 10px;
        font-weight: bold;
        color: #9d9b9b;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 20px;
        cursor: pointer;

        &:hover {
          background-color: #d4ccfc;
        }

        .icon {
          font-size: 18px;
          color: #000000;
        }

        span{
            font-size: 13px;
            font-weight: 600;
            color: #000000;
            margin-left: 10px;
        }
      }
    }
  }


  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;
    @media (max-width: 1200px) {
      display: none;
    }
    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }
}

