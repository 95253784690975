.home {
  display: flex;
  flex: 1;
  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    min-width: 100%;
  }
  
  .homeContainer {
    flex: 15;
    
    .navbar{
      height: 50px;
      padding: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0.5px solid rgb(230, 227, 227);
      @media only screen and (max-width: 1200px) {
     display: none;
      }
    }
    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      gap: 20px;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        padding: 10px;
        gap: 10px;
      }
      .chart{
        padding: 10px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        border-radius: 10px;
        span{
          font-weight: bold;
        }
        .chartTable{
          min-height: 200px;
          min-width: 400px;
          @media only screen and (max-width: 1200px) {        
          min-width: auto;
          min-height: auto;

          }
        }
      }
    }

    .charts {
      padding: 5px 20px;

      @media only screen and (max-width: 1200px) {
        padding: 5px 10px;
      }
    }

    .listContainer {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;

      @media only screen and (max-width: 1200px) {
      display: none;
      }

      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
    }
  }
}
