.datatable {
    height: 700px;
    padding: 20px;
    
    
    .datatableTitle{
      width: 100%;
      font-size: 24px;
      color: gray;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .link{
        text-decoration: none;
        color: green;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid green;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  
    .cellWithImg {
      display: flex;
      align-items: center;
  
      .cellImg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 20px;
      }
    }
  
    .cellWithStatus {
      padding: 5px;
      border-radius: 5px;
  
      &.active {
        background-color: rgba(0, 128, 0, 0.05);
        color: green;
      }
      &.pending {
        background-color: rgba(255, 217, 0, 0.05);
        color: goldenrod;
      }
      &.passive {
        background-color: rgba(255, 0, 0, 0.05);
        color: crimson;
      }
    }
  
    .cellAction {
      display: flex;
      align-items: center;
      gap: 15px;
  
      .viewButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: darkblue;
        border: 1px dotted rgba(0, 0, 139, 0.596);
        cursor: pointer;
      }
      .completeButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: rgb(0, 139, 49);
        border: 1px dotted rgba(0, 0, 139, 0.596);
        cursor: pointer;
      }
      
      .deleteButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: crimson;
        border: 1px dotted rgba(220, 20, 60, 0.6);
        cursor: pointer;
      }
    }
    @media only screen and (max-width: 900px) {
  
      .datatableTitle {
        width: 100%;
        font-size: 18px;
        margin-bottom: 5px;
  
        .link {
          font-size: 14px;
          padding: 3px;
        }
      }
  
      .cellWithImg {
        .cellImg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
  
      .cellAction {
        .viewButton,
        .completeButton,
        .deleteButton {
          padding: 2px 3px;
          font-size: 12px;
        }
        gap: 10px;
      }
    }
  }

  