.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background-color: #ffffff;
  h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color:#3E1B11;
  }
    
.form {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 70%;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    
label {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;

  input {
    border: none;
    background-color: #f4f4f4;
    font-size: 16px;
    height: 40px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
  }
}

  
.button {
  border: none;
  color: #fff;
  background-color: #3E1B11;
  font-size: 16px;
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
}
}
}




