.addNewTop,
.addNewBottom {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 20px;
  display: flex;
  align-items: center;
  .right {
    padding: 20px;
    border-radius: 5px;
    img{
      width: 200px;
      height: 200px;
    }
    .formInput {
      margin-bottom: 10px;
      
      label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: #333;
      }
      
      input, select {
        display: block;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        width: 100%;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
      }
      
      select {
        padding-right: 35px;
        appearance: none;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41zM12 4h-2v6H8v2h2v6h2v-6h2V10h-2z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 10px center;
      }
    }
    button {
      display: block;
      margin-top: 15px;
      padding: 10px 20px;
      border: none;
      border-radius: 3px;
      background-color: #003902;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
    
    button:hover {
      background-color: #000000;
    }

  }
  
}